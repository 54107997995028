import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/xjoin', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Clients', {
            _size: 1,
            _p: 1,
            _sort: '',
            _where: `(id,eq,${id})`,
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Clients',
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addClient(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Clients', clientData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateClient(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/updateRow/Clients/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/deleteRow/Clients/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchUsers() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Users', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Users',
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
