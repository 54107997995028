import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useClientsList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'Клиент', sortable: true },
    { key: 'balance', label: 'Баланс', sortable: false },
    { key: 'responsible', label: 'Отвественный', sortable: true },
    { key: 'phone', label: 'Телефон', sortable: true },
    { key: 'city', label: 'Город', sortable: true },
    { key: 'address', label: 'Адрес', sortable: true },
    { key: 'discount', label: 'Скидка', sortable: false },
    { key: 'comments', label: 'комментарии', sortable: true },
    { key: 'actions' },
    // {
    //   key: 'resp_user_id',
    //   label: 'Responsible',
    //   // formatter: title,
    //   sortable: true,
    // },
  ]

  const queryFields = [
    { label: 'id', whereKeys: ['cl.id', 'cl.firstname', 'cl.surname', 'cl.type'], sortKey: 'cl.id' },
    { label: 'responsible', whereKeys: ['cl.resp_user_id', 'u0.firstName', 'u0.lastName', 'u0.email'], sortKey: 'u0.firstName' },
    { label: 'phone', whereKeys: ['cl.phone'], sortKey: 'cl.phone' },
    { label: 'city', whereKeys: ['cl.city'], sortKey: 'cl.city' },
    { label: 'address', whereKeys: ['cl.address'], sortKey: 'cl.address' },
    { label: 'comments', whereKeys: ['cl.comments'], sortKey: 'cl.comments' },
    { label: 'discount', whereKeys: ['cl.discount'], sortKey: 'cl.discount' },
    // { label: 'cost', whereKeys: ['cl.cost'], sortKey: 'cl.cost' },
    // { label: 'quantity', whereKeys: ['cl.quantity'], sortKey: 'cl.quantity' },
    // { label: 'weight', whereKeys: ['cl.weight'], sortKey: 'cl.weight' },
    // { label: 'id'whereKeys w[hereKeys: ['cl.service'], sortKey: 'cl.service' },
    // { label: 'dueDate'whereKeys w[hereKeys: ['cl.dueDate'], sortKey: 'cl.dueDate' },
    // { label: 'client_id', whereKeys: ['cl.client_id'], sortKey: 'cl.client_id' },
    // { label: 'wm_id', whereKeys: ['cl.wm_id'], sortKey: 'cl.wm_id' },
    // { label: 'order_name', whereKeys: ['cl.order_name'], sortKey: 'cl.order_name' },
    // { label: 'firstname', whereKeys: ['sp.firstname'], sortKey: 'sp.firstname' },
    // { label: 'surname', whereKeys: ['sp.surname'], sortKey: 'sp.surname' },
    // { label: 'phone', whereKeys: ['sp.phone'], sortKey: 'sp.phone' },
    // { label: 'name', whereKeys: ['wm.name'], sortKey: 'wm.name' },
    // { label: 'city', whereKeys: ['wm.city'], sortKey: 'wm.city' },
    // { label: 'address', whereKeys: ['wm.address'], sortKey: 'wm.address' },
    // { label: 'type', whereKeys: ['wm.type'], sortKey: 'wm.type' },
  ]

  const perPage = ref(10)
  const totalClients = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalClients.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const q = (val, joiner) => {
    const where = []
    queryFields.forEach(field => {
      field.whereKeys.forEach(column => {
        where.push(`(${column},like,~${val}~)`)
      })
    })

    // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < queryFields.length; i++) {
    //   const columns = queryFields[i].whereKeys
    //   columns.forEach(column => {
    //     where.push(`(${column},like,~${val}~)`)
    //   })
    // }
    return joiner ? `~and(${where.join('~or')})` : where.join('~or')
  }

  const fetchClients = (ctx, callback) => {
    store
      .dispatch('app-client/fetchClients', {
        _join: 'cl.Clients,_lj,u0.Users',
        _on1: '(cl.resp_user_id,eq,u0.id)',
        _size: perPage.value,
        _p: currentPage.value,
        _sort: (isSortDirDesc.value === true ? '-' : '') + queryFields.filter(item => item.label === sortBy.value)[0].sortKey,
        _where: (statusFilter.value !== null ? `(cl.type,eq,${statusFilter.value})` : '') + (searchQuery.value !== '' ? q(searchQuery.value, statusFilter.value !== null) : ''),
        _fields: 'cl.id,cl.firstname,cl.surname,cl.phone,u0.email,cl.discount,cl.city,cl.address,cl.type,cl.comments,cl.resp_user_id,cl.defaults,cl.createdAt,cl.updatedAt,u0.firstName,u0.lastName',
        _draw: 1,
        _tableconstruction_version: 'vertri',
        _zeTable: 'Clients',
      })
      .then(response => {
        const { data, recordsTotal } = response.data

        callback(data)
        totalClients.value = recordsTotal
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const deleteClient = id => {
    store.dispatch('app-client/deleteClient', { id })
      .then(response => {
        if (response.data.code === 1) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          // resetForm()
          refetchData()
        } else {
          throw new Error(response.data.status)
          // purchaseData.value = response.data
        }
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      })
      .finally(() => {
        // asdad
      })
  }

  return {
    fetchClients,
    tableColumns,
    perPage,
    currentPage,
    totalClients,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    deleteClient,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
